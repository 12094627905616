document.addEventListener("DOMContentLoaded", function() {
    if (document.getElementById('scroll-block')) {
        let lastKnownScrollPosition = 0;
        let ticking = false;
        let scrollBlock = document.getElementById('scroll-block');
        let header = document.getElementsByClassName('header');

        let scrollDir = 0;
        let documentHeight = document.body.getBoundingClientRect().height;

        // Side container info
        let sideContainer = document.getElementsByClassName('split-view__side')[0];
        let sideContainerFullHeight = sideContainer.getBoundingClientRect().height;
        sideContainerFullHeight = sideContainerFullHeight + offset(sideContainer).top;

        let fromTopInit = offset(sideContainer).top - header[0].getBoundingClientRect().height;

        // Get distance between side container bottom en document bottom + offset because of more-info-search
        let distanceSideBottomDocBottom = documentHeight - sideContainerFullHeight + 180;

        function doSomething(scrollPos) {
            // Calculate scrollposition for fixed position
            let fromTop = scrollBlock.getBoundingClientRect().top;
            fromTop = fromTop - (header[0].getBoundingClientRect().height - 1);

            let distanceSideContainerFromTop = scrollPos + offset(sideContainer).top + scrollBlock.getBoundingClientRect().height;
            // console.log(distanceSideContainerFromTop);

            // Recalculate sideContainerFullHeight because min-height setted on pageload
            sideContainerFullHeight = sideContainer.getBoundingClientRect().height;
            sideContainerFullHeight = sideContainerFullHeight + offset(sideContainer).top;

            let scrollPosWhenFixed = scrollBlock.getBoundingClientRect().height + scrollPos;

            // Detect scroll direction
            if (document.body.getBoundingClientRect().top < scrollDir) {
                // console.log('down')
                // Be sticky
                if (fromTop <= 0) {
                    activateSticky();
                }

                // Stop being sticky at end of container and be absolute at bottom
                if (scrollPosWhenFixed > (sideContainerFullHeight - 300)) {
                    hideScrollBlock();
                }

            } else {
                // console.log('up');
                // Stop begin sticky
                if (scrollPos < fromTopInit) {
                    deactivateSticky();
                }

                // Be sticky when scrolling up from bottom
                if (scrollPosWhenFixed < (sideContainerFullHeight - 300)) {
                    showScrollBlock();
                }

            }

            scrollDir = document.body.getBoundingClientRect().top;
        }

        // Efficient scroll listener
        document.addEventListener('scroll', function(e) {
            lastKnownScrollPosition = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(function() {
                    doSomething(lastKnownScrollPosition);
                    // activeAnchor(lastKnownScrollPosition);
                    ticking = false;
                });

                ticking = true;
            }
        });

        function activateSticky() {
            if (!scrollBlock.classList.contains('split-view__scroll-block--sticky')) {
                scrollBlock.classList.add('split-view__scroll-block--sticky');
                scrollBlock.style.top = '79px';
            }
        }

        function deactivateSticky() {
            if (scrollBlock.classList.contains('split-view__scroll-block--sticky')) {
                scrollBlock.classList.remove('split-view__scroll-block--sticky');
                scrollBlock.style.top = '0';
            }
        }

        function hideScrollBlock() {
            if (!scrollBlock.classList.contains('split-view__scroll-block--hide')) {
                scrollBlock.classList.add('split-view__scroll-block--hide');
            }
        }

        function showScrollBlock() {
            if (scrollBlock.classList.contains('split-view__scroll-block--hide')) {
                scrollBlock.classList.remove('split-view__scroll-block--hide');
            }
        }

        function offset(el) {
            let rect = el.getBoundingClientRect(),
                scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
        }

        // Active anchor by scroll position
        let a_sections = document.getElementsByClassName('products__section');
        let a_nav = document.getElementsByClassName('split-view__list-item');
        let scrollDir2 = 0;

        let executeOnce = false;
        let prevId = '';

        function activeAnchor(scrollPos) {
            let cur_pos = scrollPos;

            if (a_sections.length >= 1) {
                for (var i = 0; i < a_sections.length; i++) {
                    let top = a_sections[i].getBoundingClientRect().top - 130,
                        bottom = top + a_sections[i].getBoundingClientRect().height,
                        id = a_sections[i].getAttribute('id'),
                        prevId = '';

                    // console.log(a_sections[0].getBoundingClientRect().top);

                    // if (top < 0 && bottom > 0) {
                    //     console.log(i);
                    // }

                    // if (cur_pos >=  a_sections[0].getBoundingClientRect().top + 450 && cur_pos <= a_sections[0].getBoundingClientRect().top + 450 + a_sections[i].getBoundingClientRect().height) {
                    //     console.log('1',cur_pos, a_sections[0].getBoundingClientRect().top + 450, a_sections[0].getBoundingClientRect().top + 450 + a_sections[i].getBoundingClientRect().height);
                    // }
                    // if (cur_pos >=  a_sections[1].getBoundingClientRect().top + 450 && cur_pos <= a_sections[1].getBoundingClientRect().top + 450 + a_sections[i].getBoundingClientRect().height) {
                    //     console.log('2',cur_pos, a_sections[1].getBoundingClientRect().top + 450, a_sections[1].getBoundingClientRect().top + 450 + a_sections[i].getBoundingClientRect().height);
                    // }

                    if (top < 0 && bottom > 0) {
                        // console.log(i);

                        if (executeOnce === false) {
                            prevId = id;

                            // Remove active class
                            Array.from(a_nav).forEach(function(el) {
                                el.classList.remove('split-view__list-item--active');
                            });

                            // Set active class for current item
                            anchorId = id.replace('_scrollto', '');
                            if (document.querySelector('a[href="#'+anchorId+'"]')) {
                                document.querySelector('a[href="#'+anchorId+'"]').parentElement.classList.add('split-view__list-item--active');
                            }

                            // executeOnce = true;
                        }
                        // if (prevId !== id) {
                        //     executeOnce = false;
                        // }
                    }

                    if (cur_pos === 0) {
                        Array.from(a_nav).forEach(function(el) {
                            el.classList.remove('split-view__list-item--active');
                        });

                        a_nav[0].classList.add('split-view__list-item--active');
                    }
                }
            }
        }

        // Scrollto on click (scrollBlock nav items)
        // Disabled - feedback DRRD
        // let scrollBlockAnchors = document.querySelectorAll('.split-view__list-item a');
        // Array.from(scrollBlockAnchors).forEach(function(element) {
        //     element.addEventListener('click', function(e) {
        //         e.preventDefault();
        //         let href = this.getAttribute('href');
        //         if (href.includes('#')) {
        //             href = href.substring(1);
        //             href = href + '_scrollto';
        //             let scrollEL = document.getElementById(href);
        //             if (scrollEL) {
        //                 const yOffset = -80;
        //                 const y = scrollEL.getBoundingClientRect().top + window.pageYOffset + yOffset;
        //
        //                 window.scrollTo({top: y, behavior: 'smooth'});
        //             }
        //         }
        //     });
        // });

        // Set min-height for container
        let splitViewContentEl = document.querySelector('.split-view__content-inner');
        let sideInnerEl = document.querySelector('.split-view__side-inner');
        let sideScrollBlockEl = document.querySelector('.split-view__scroll-block');

        if (sideInnerEl) {
            let sideScrollBlockElHeight = sideScrollBlockEl.getBoundingClientRect().height;
            sideScrollBlockElHeight = sideScrollBlockElHeight + 275;
            sideScrollBlockElHeight += 'px';
            sideInnerEl.style.minHeight = sideScrollBlockElHeight;

        }
    }
});