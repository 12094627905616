document.addEventListener("DOMContentLoaded", function() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    // Set height of text container in article because of css limitations (align-items: baseline with multiple text elements)
    let articleTextEls = document.getElementsByClassName('article__text');
    for (aTC of articleTextEls) {
        let aTCHeight = aTC.clientHeight;
        let containerHeight = aTCHeight + 170;
        if (vw < 768) {
            containerHeight = aTCHeight + 100;
        }

        let parent = aTC.parentElement;
        parent.style.height = containerHeight + 'px';
    }

    let heroSliderScrollButton = document.querySelector('.hero-slider__nav-button--scroll-to');
    if (heroSliderScrollButton) {
        heroSliderScrollButton.addEventListener('click', function(e) {
            e.preventDefault();
            let href = this.getAttribute('href');
            if (href.includes('#')) {
                href = href.substring(1);
                let scrollEL = document.getElementById(href);
                scrollEL.scrollIntoView();
            }
        });
    }

    // Play video: fade out image en start YouTube embed
    let videoSections = document.querySelectorAll('.editor__wide-image--video .editor__image-container');

    if (videoSections) {
        // This code loads the IFrame Player API code asynchronously.
        var tag = document.createElement('script');

        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        let newPlayers = [];

        window.onYouTubeIframeAPIReady = function(videoID) {
            let sectionCount = 0;
            Array.from(videoSections).forEach(function(videoSection) {
                let newPlayer;
                newPlayer = new YT.Player('player-'+ sectionCount +'');
                newPlayers.push(newPlayer);

                sectionCount++;
            });
        }

        // console.log(newPlayers);

        Array.from(videoSections).forEach(function(videoSection) {
            videoSection.addEventListener('click', function(e) {
                this.classList.add('editor__image-container--hide');
                let iframeYT = this.parentElement.querySelector('iframe');
                if (iframeYT) {
                    let iframeCount = iframeYT.getAttribute('data-player-count');
                    if (iframeCount) {
                        iframeCount = parseInt(iframeCount)
                        let player = newPlayers[iframeCount];
                        player.playVideo();
                    }
                }
            })
        });
    }

    // Remove table scroll icon when horizontally scrolled in table
    let tableContainerEl = document.querySelector('.table-responsive');
    let addedScrolledClass = false;
    if (tableContainerEl !== null) {
        tableContainerEl.addEventListener("scroll", function(){
            if (addedScrolledClass == false) {
                tableContainerEl.classList.add('table-responsive--scrolled');
            }

            addedScrolledClass = true;

        }, false);
    }

    // When split-view side needs te be visible on mobile we need some js to open/close it
    let splitViewSideMobile = document.querySelector('.split-view__side--has-mobile-view');
    if (splitViewSideMobile) {
        if (vw < 991) {
            let trigger = document.querySelector('.split-view__side-mobile-trigger');
            let nav = document.querySelector('.split-view__nav');
            trigger.addEventListener('click', function() {
                nav.classList.toggle('split-view__nav--open');
            })
        }
    }
});