document.addEventListener("DOMContentLoaded", function() {

    // How to help block on homepage
    //
    // Show on pageload - close on close-click, show on scroll top
    //
    if (document.querySelector('.how-to-help')) {
        const breakpoint = window.matchMedia( '(min-width:992px)' );
        const closeButton = document.querySelector('.how-to-help__close');
        const howToHelpModal = document.querySelector('.how-to-help');
        const howToHelpSearchInput = document.querySelector('.how-to-help__form-input');
        const howToHelpSearchResults = document.querySelector('.how-to-help__search-results');
        let howToHelpIsVisible = true;

        // Close on click
        closeButton.addEventListener('click', () => {
           closeModal();
        });

        // Close with escape button
        document.addEventListener('keyup', function(e) {
           if (e.key == 'Escape' && howToHelpModal.classList.contains('how-to-help--show')) {
               closeModal();
           }

            // Close how-to-help__search-results after tabbing out
            if (e.key == 'Tab' && howToHelpSearchResults.classList.contains('how-to-help__search-results--show')) {
                // console.log(e.target.classList.contains('search-modal__results-close'));
                if (e.target.classList.contains('search-modal__results-close') == true) {
                    howToHelpSearchResults.classList.remove('how-to-help__search-results--show');
                }
            }
        });



        // Close modal, remove class, update style transition
        function closeModal() {
            howToHelpModal.style.transition = 'opacity .3s ease, visibility .3s ease, transform .3s ease';
            howToHelpModal.classList.remove('how-to-help--show');
            howToHelpIsVisible = false;
            toggleSlideContentVisibility();
        }

        // Show or hide slide-content.
        function toggleSlideContentVisibility() {
            if (document.querySelectorAll('.hero-slider__slide-content')) {
                document.querySelectorAll('.hero-slider__slide-content').forEach(item => {
                    if (howToHelpIsVisible == false) {
                        item.classList.add('hero-slider__slide-content--show');
                    } else {
                        item.classList.remove('hero-slider__slide-content--show');
                    }
                });
            }
        }

        const breakpointChecker = function() {
            if (breakpoint.matches !== true) {
                setTimeout(() => {
                    document.querySelector('.how-to-help').classList.add('how-to-help--show');
                }, 280);
            }
        };

        breakpointChecker();

        setTimeout(() => {
            window.addEventListener("scroll", function() {
                // console.log(window.scrollY, window.getComputedStyle(howToHelpModal).opacity);
                if (window.scrollY === 0 && window.getComputedStyle(howToHelpModal).opacity === '0') {
                    setTimeout(() => {
                        document.querySelector('.how-to-help').classList.add('how-to-help--show');
                        howToHelpIsVisible = true;
                        toggleSlideContentVisibility();
                    }, 280);
                }
            });
        }, 500);

        // Search results dropdown show
        // howToHelpSearchInput.addEventListener('focus', function() {
        //     howToHelpSearchResults.classList.add('how-to-help__search-results--show');
        // });

        // Search results dropdown hide
        document.addEventListener('click', function(event) {
            let isClickInsideElement = howToHelpModal.contains(event.target);
            if (!isClickInsideElement) {
                howToHelpSearchResults.classList.remove('how-to-help__search-results--show');
            }
        });

        // Hide after tab on last search result item
        // let searchModalLi = document.getElementsByClassName('search-modal__li');
        // console.log(searchModalLi[searchModalLi.length - 1].querySelector('a'));
        // searchModalLi[searchModalLi.length - 1].querySelector('a').addEventListener('keydown', function(e) {
        //     console.log(e);
        //     if (e.key == 'Tab') {
        //         howToHelpSearchResults.classList.remove('how-to-help__search-results--show');
        //     }
        // });
    }
});