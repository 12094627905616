import Tooltip from 'bootstrap/js/dist/tooltip';

document.addEventListener("DOMContentLoaded", function() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    // Keep labels up when element has value
    document.querySelectorAll('input[type="search"]:not(.how-to-help__form-input), input[type="text"], input[type="textarea"], input[type="email"], input[type="password"], input[type="url"], input[type="number"], input[type="tel"], textarea').forEach(item => {
        item.addEventListener('blur', function () {
            let parent = this.parentElement;
            if (parent) {
                if (item.value.length > 0) {
                    parent.classList.add('form__field--filled');
                } else if (item.value.length === 0) {
                    parent.classList.remove('form__field--filled');
                }
            }
        });

        item.addEventListener('focus', function () {
            let parent = this.parentElement;
            if (parent) {
                parent.classList.add('form__field--filled');
            }
        });

        let parent = item.parentElement;
        if (parent) {
            if (item.value.length > 0) {
                parent.classList.add('form__field--filled');
            }
        }

    });

    // Bootstrap tooltip
    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })
});